








import ExchangeSumary from '@/views/DashBoard/components/ExchangeSummary/index.vue'
import ExchangeConnectForm from './components/ExchangeConnectForm.vue'
import SupportExchanges from './components/SupportExchanges.vue'

export default {
  components: {
    ExchangeSumary,
    ExchangeConnectForm,
    SupportExchanges
  }
}
